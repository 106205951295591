import store from '@/store'

import {computed} from '@vue/composition-api'

export default function useCalendarSidebar() {
    // ------------------------------------------------
    // calendarOptions
    // ------------------------------------------------
    const calendarOptions = computed(() => store.state.calendar.calendarOptions)

    // ------------------------------------------------
    // selectedCalendars
    // ------------------------------------------------
    const selectedCalendars = computed({
        get: () => store.state.calendar.selectedCalendars,
        set: val => {
            store.commit('calendar/SET_SELECTED_EVENTS', val)
            store.commit('calendar/SET_DOCTOR_CALENDARS', val)
            store.dispatch('calendar/fetchEvents', {
                calendars: val,
            })
        },
    })

    // ------------------------------------------------
    // checkAll
    // ------------------------------------------------
    const checkAll = computed({
        /*
          GET: Return boolean `true` => if length of options matches length of selected filters => Length matches when all events are selected
          SET: If value is `true` => then add all available options in selected filters => Select All
               Else if => all filters are selected (by checking length of both array) => Empty Selected array  => Deselect All
        */
        get: () => selectedCalendars.value.length === calendarOptions.value.length,
        set: val => {
            if (val) {
                selectedCalendars.value = calendarOptions.value.map(i => i.id)
            } else if (selectedCalendars.value.length === calendarOptions.value.length) {
                selectedCalendars.value = [0]
            }
        },
    })

    return {
        calendarOptions,
        selectedCalendars,
        checkAll,
    }
}
